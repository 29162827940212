// --------------------------------------------------------------------------------
// <copyright file="flowSpeedGanttGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------
import { AbstractGanttChartTableGenerator } from '@/models/Charts/gantt/abstractGanttChartTableGenerator';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { getBendFlowSpeedColor } from '@/utils/color';
import {
  BEND_FLOW_SPEED,
  BendSpeedFlowStates,
  FlowSpeedGanttState,
  GanttTableData,
} from '../chartsData';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import i18n from '@/i18n';
export class FlowSpeedGanttGenerator extends AbstractGanttChartTableGenerator<FlowSpeedGanttState> {
  constructor() {
    super(WidgetEnum.BendFlowSpeedStatesGanttTable);
  }

  override async getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ): Promise<GanttTableData<any>[] | null> {
    throw new Error('This method does not need to be called nor implemented');
  }

  protected override getStateColor(state: FlowSpeedGanttState, index: number) {
    return getBendFlowSpeedColor(state);
  }

  protected override getCustomItemStyle(state: FlowSpeedGanttState) {
    if (state === BendSpeedFlowStates.Processing) {
      return {
        borderColor: '#9C732C',
        borderType: 'dashed',
      };
    }

    return {};
  }

  protected override getStates(data: GanttTableData<FlowSpeedGanttState>[]): FlowSpeedGanttState[] {
    return BEND_FLOW_SPEED;
  }

  protected override getLocalizedStateName(state: string): string {
    return i18n.t(`widget_info_text.gantt_chart_table.states.${state}`).toString();
  }
}
