// --------------------------------------------------------------------------------
// <copyright file="productionModeGanttGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------
import { AbstractGanttChartTableGenerator } from '@/models/Charts/gantt/abstractGanttChartTableGenerator';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { getBendStateColor } from '@/utils/color';
import { BEND_PRODUCTION_MODES, BendState, GanttTableData } from '../chartsData';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import i18n from '@/i18n';
export class ProductionModeGanttGenerator extends AbstractGanttChartTableGenerator<BendState> {
  constructor() {
    super(WidgetEnum.BendStatesGanttTable);
  }

  override async getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ): Promise<GanttTableData<any>[] | null> {
    throw new Error('This method does not need to be called nor implemented');
  }

  protected override getStateColor(state: BendState, index: number) {
    return getBendStateColor(state);
  }

  protected override getStates(data: GanttTableData<BendState>[]): BendState[] {
    return BEND_PRODUCTION_MODES;
  }

  protected override getLocalizedStateName(state: string): string {
    return i18n.t(`widget_info_text.gantt_chart_table.states.${state}`).toString();
  }
}
