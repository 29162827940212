// --------------------------------------------------------------------------------
// <copyright file="sheetPartsGanttGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------
import { AbstractGanttChartTableGenerator } from '@/models/Charts/gantt/abstractGanttChartTableGenerator';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { GanttTableData } from '../chartsData';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { uniq } from '@/utils/array';

export class SheetPartsGanttGenerator extends AbstractGanttChartTableGenerator<string> {
  private static PALETTE_COLORS: string[] = [
    '#ff5252',
    '#fb9755',
    '#f7d658',
    '#d5f25a',
    '#99ee5d',
    '#63e595',
    '#67e0c6',
    '#6ac7db',
    '#6d9ad7',
    '#7173d2',
    '#9574cd',
    '#d468df',
    '#ef5cac',
  ];

  constructor() {
    super(WidgetEnum.BendSheetPartsGanttTable);
  }

  override async getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ): Promise<GanttTableData<any>[] | null> {
    throw new Error('This method does not need to be called nor implemented');
  }

  protected override getStateColor(state: string, index: number) {
    return SheetPartsGanttGenerator.PALETTE_COLORS[
      index % SheetPartsGanttGenerator.PALETTE_COLORS.length
    ];
  }

  override getStates(data: GanttTableData<string>[]): string[] {
    // In this case, we can assume that state is the name of the part.
    return uniq(data.map((x) => x.state));
  }

  protected override getLocalizedStateName(state: string): string {
    return state;
  }
}
